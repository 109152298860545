
import React from "react";



const HomePage = (props) => {
    return (
        <div>
            <h1>{props.content}</h1>
            <h4>some content</h4>
        </div>
    );
};

export default HomePage;